<script lang="ts">
  import { page } from "$app/stores";
  import favicon from "~/lib/assets/images/favicon.webp";

  const defaultTitle = "Winterbolt Games";
  const defaultType = "website";
  const defaultImage = favicon;
  const defaultUrl = "https://winterboltgames.com";
  const defaultDescription =
    "Winterbolt Games is a software developer and content creator.";
  const defaultSiteName = "Winterbolt Games";
  const defaultTwitter = "@winterboltgames";

  interface Props {
    title?: string;
    type?: string;
    image?: string;
    url?: string;
    description?: string;
    siteName?: string;
    twitter?: string;
  }

  let {
    title = defaultTitle,
    type = defaultType,
    image = defaultImage,
    url = $page.url.pathname,
    description = defaultDescription,
    siteName = defaultSiteName,
    twitter = defaultTwitter
  }: Props = $props();

  function getUrl() {
    return url.startsWith(defaultUrl) ? url : `${defaultUrl}${url}`;
  }
</script>

<svelte:head>
  <title>{title}</title>
  <meta name="title" content={title} />
  <meta name="description" content={description} />
  <meta property="og:type" content={type} />
  <meta property="og:url" content={getUrl()} />
  <meta property="og:title" content={title} />
  <meta property="og:description" content={description} />
  <meta property="og:image" content={image} />
  <meta property="og:site_name" content={siteName} />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:url" content={getUrl()} />
  <meta name="twitter:title" content={title} />
  <meta name="twitter:description" content={description} />
  <meta name="twitter:image" content={image} />
  <meta name="twitter:site" content={twitter} />
  <meta name="twitter:creator" content={twitter} />
</svelte:head>
